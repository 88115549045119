import { XpRewardConfig } from '../store/defaultCampaigns';
import { GUILDS, NUM_GUILD_LEVELS } from './constants';
import {
  ARENA_SIZES,
  ArenaSize,
  Campaign,
  CampaignLevel,
  CampaignMission,
  CampaignMissionExport,
  Campaigns,
  CampaignsExport,
  DarkboundStats,
  Difficulty,
  ExportData,
  Guild,
  GuildProgression,
  GuildProgressions,
  GuildStat,
  GuildStats,
  MISSION_CRITERIA,
  MissionCriteria,
  UNLOCKABLES,
  Unlockable,
} from './types';

export function compound(
  base: number,
  multiplier: number,
  time: number,
  multiplierFn?: (value: number) => number
) {
  if (multiplierFn) return base * Math.pow(multiplierFn(multiplier), time);
  return base * Math.pow(multiplier, time);
}

export function roundToTens(value: number) {
  return Math.ceil(value / 10) * 10;
}

const formatter = new Intl.NumberFormat('en-US').format;
export function formatNumber(value: number) {
  return formatter(value);
}

export function findGuildByLevelId(
  campaigns: Record<Guild, Campaign>,
  levelId: string
) {
  let guild: Guild;
  let campaign: Campaign;
  for (let i = 0; i < GUILDS.length; i++) {
    guild = GUILDS[i];
    campaign = campaigns[guild];
    if (campaign?.levels) {
      for (let j = 0; j < campaign.levels.length; j++) {
        if (campaign.levels[j].id === levelId) {
          return guild;
        }
      }
    }
  }
  return null;
}

export function findLevelById(
  campaigns: Record<Guild, Campaign>,
  levelId: string
) {
  let guild: Guild;
  let campaign: Campaign;
  for (let i = 0; i < GUILDS.length; i++) {
    guild = GUILDS[i];
    campaign = campaigns[guild];
    if (campaign?.levels) {
      for (let j = 0; j < campaign.levels.length; j++) {
        if (campaign.levels[j].id === levelId) {
          return campaign.levels[j];
        }
      }
    }
  }
  return null;
}

export function getTotalXPRewardFirst(level: CampaignLevel) {
  return level.missions.reduce(
    (sum, mission) => sum + mission.xpRewardFirst,
    0
  );
}

export function getTotalXPReward(level: CampaignLevel) {
  return level.missions.reduce((sum, mission) => sum + mission.xpReward, 0);
}

export function getAvgXPRewardFirst(level: CampaignLevel) {
  return level.missions[0].xpRewardFirst + level.missions[1].xpRewardFirst;
}

export function getAvgXPReward(level: CampaignLevel) {
  return level.missions[0].xpReward + level.missions[1].xpReward;
}

export function getMinXPRewardFirst(level: CampaignLevel) {
  return level.missions[0].xpRewardFirst;
}

export function getMinXPReward(level: CampaignLevel) {
  return level.missions[0].xpReward;
}

export function updateDarkboundLevels(
  campaigns: Campaigns,
  guildProgressions: GuildProgressions
): Campaigns {
  if (!guildProgressions) return campaigns;
  return Object.fromEntries(
    Object.keys(campaigns).map((guild) => [
      guild,
      {
        ...campaigns[guild as Guild],
        levels: updateCampaignDarkboundLevels(
          campaigns[guild as Guild].levels,
          guildProgressions[guild as Guild]
        ),
      },
    ])
  ) as unknown as Campaigns;
}

export function updateCampaignDarkboundLevels(
  levels: CampaignLevel[],
  guildProgression: GuildProgression
): CampaignLevel[] {
  if (!guildProgression) return levels;
  const updatedLevels = JSON.parse(JSON.stringify(levels));
  let lastCampaignLevel =
    guildProgression[guildProgression.length - 1].campaignLevel;
  let darkboundLevel = guildProgression.length - 1;
  for (let i = guildProgression.length - 1; i >= 0; i--) {
    let campaignLevel = guildProgression[i].campaignLevel;
    if (campaignLevel !== lastCampaignLevel) {
      darkboundLevel = i;
      lastCampaignLevel = campaignLevel;
    }
    updatedLevels[campaignLevel].darkboundLevel = darkboundLevel;
  }
  let darkboundLevelEasy = 0;
  let darkboundLevelHard = 0;
  let darkboundIncrement = 0;
  lastCampaignLevel = 0;
  for (let i = 0; i < guildProgression.length; i++) {
    let campaignLevel = guildProgression[i].campaignLevel;
    if (campaignLevel !== lastCampaignLevel) {
      darkboundLevelEasy = i;
      lastCampaignLevel = campaignLevel;
      darkboundIncrement = 0;
    }
    darkboundLevelHard = Math.min(
      guildProgression.length - 1,
      i + darkboundIncrement
    );
    if (darkboundLevelEasy === campaignLevel) {
      darkboundLevelEasy = Math.max(0, campaignLevel - 1);
      darkboundLevelHard = Math.min(
        guildProgression.length - 1,
        campaignLevel + 1
      );
    }
    updatedLevels[campaignLevel].darkboundLevelEasy = darkboundLevelEasy;
    updatedLevels[campaignLevel].darkboundLevelHard = darkboundLevelHard;
    ++darkboundIncrement;
  }

  // add levels for level 13
  updatedLevels[12].darkboundLevel = updatedLevels[11].darkboundLevel;
  updatedLevels[12].darkboundLevelEasy = updatedLevels[11].darkboundLevelEasy;
  updatedLevels[12].darkboundLevelHard = updatedLevels[11].darkboundLevelHard;
  return updatedLevels;
}

export function updateCampaignProgressions(
  campaigns: Record<Guild, Campaign>,
  xpRewardConfig: XpRewardConfig
): Record<Guild, Campaign> {
  const updatedCampaigns = JSON.parse(JSON.stringify(campaigns)) as Record<
    Guild,
    Campaign
  >;
  return Object.fromEntries(
    Object.keys(updatedCampaigns).map((key) => {
      const guild = key as Guild;
      const campaign = updatedCampaigns[guild];
      return [
        guild,
        {
          ...campaign,
          levels: campaign.levels.map((level, index) => {
            const xpRewardFirst =
              index === 0
                ? xpRewardConfig.baseXp
                : roundToTens(
                    compound(
                      xpRewardConfig.baseXp,
                      xpRewardConfig.levelMultiplier,
                      index
                    )
                  );
            return {
              ...level,
              missions: level.missions.map((mission, missionIndex) => ({
                ...mission,
                xpRewardFirst:
                  xpRewardFirst *
                  Math.pow(xpRewardConfig.tierMultiplier, missionIndex),
                xpReward: roundToTens(
                  xpRewardFirst *
                    xpRewardConfig.grindMultiplier *
                    Math.pow(xpRewardConfig.tierMultiplier, missionIndex)
                ),
              })),
            };
          }),
        },
      ];
    })
  ) as Record<Guild, Campaign>;
}

export function generateGuildProgression(
  levelCount: number,
  campaigns: Record<Guild, Campaign>,
  guild: Guild,
  xpRewardConfig?: XpRewardConfig
): GuildProgression {
  const numLevels = Array(levelCount).fill(0);
  const campaign = campaigns[guild];
  const result: GuildProgression = [];
  for (let i = 0; i < numLevels.length; i++) {
    result.push({
      xp: 0,
      campaignLevel: 0,
      grind: 0,
      grindAvg: 0,
      grindWorst: 0,
      xpReward: 0,
    });
  }
  if (!campaign || !xpRewardConfig) return result;

  let runningXp = 0;
  let levelIndex = 0;
  let grindCount = 0;
  for (let i = 0; i < numLevels.length; i++) {
    let maxGrind = Math.round(
      Math.pow(xpRewardConfig.grindCoefficient, levelIndex)
    );
    let level = campaign.levels[levelIndex];
    let xpRewardFirst = getTotalXPRewardFirst(level);
    let xpRewardGrind = getTotalXPReward(level);
    let xpReward = grindCount === 0 ? xpRewardFirst : xpRewardGrind;
    result[i].xp = roundToTens(runningXp);
    result[i].campaignLevel = levelIndex;
    result[i].grind = grindCount;
    result[i].grindAvg = 0;
    result[i].grindWorst = 0;
    result[i].xpReward = xpReward;
    runningXp += xpReward;
    if (++grindCount >= maxGrind) {
      ++levelIndex;
      grindCount = 0;
    }
  }

  // calculate average grind count
  grindCount = 0;
  runningXp = 0;
  for (let i = 0; i < numLevels.length; i++) {
    if (i > 0) {
      let level = campaign.levels[levelIndex];
      let xpRewardFirst = getAvgXPRewardFirst(level);
      let xpRewardGrind = getAvgXPReward(level);
      while (runningXp < result[i].xp) {
        let xpReward = grindCount === 0 ? xpRewardFirst : xpRewardGrind;
        runningXp += xpReward;
        ++grindCount;
        if (runningXp >= result[i].xp) {
          result[i].grindAvg = grindCount;
          grindCount = 0;
          break;
        }
      }
    }
  }

  // calculate worst grind count
  grindCount = 0;
  runningXp = 0;
  for (let i = 0; i < numLevels.length; i++) {
    if (i > 0) {
      let level = campaign.levels[levelIndex];
      let xpMinRewardFirst = getMinXPRewardFirst(level);
      let xpMinRewardGrind = getMinXPReward(level);
      while (runningXp < result[i].xp) {
        let xpReward = grindCount === 0 ? xpMinRewardFirst : xpMinRewardGrind;
        runningXp += xpReward;
        ++grindCount;
        if (runningXp >= result[i].xp) {
          result[i].grindWorst = grindCount;
          grindCount = 0;
          break;
        }
      }
    }
  }

  return result;
}

export function getGuildStatSum(guildStat: GuildStat) {
  return Object.keys(guildStat).reduce(
    (sum, stat) => sum + guildStat[stat as keyof GuildStat],
    0
  );
}

export function getWeakestGuildStat(guildStats: GuildStats, guild: Guild) {
  const { start, max } = guildStats[guild];
  const statKeys = Object.keys(start);
  let weakestStat = statKeys[0] as keyof GuildStat;
  let highestDiff = max[weakestStat] - start[weakestStat];
  for (let i = 0; i < statKeys.length; i++) {
    const stat = statKeys[i] as keyof GuildStat;
    const diff = max[stat] - start[stat];
    if (diff > highestDiff) {
      weakestStat = stat;
      highestDiff = diff;
    }
  }
  return weakestStat;
}

export function generateIdealGuildLevelStat(
  guildStats: GuildStats,
  guild: Guild,
  level: number
) {
  const idealGuildStats = JSON.parse(JSON.stringify(guildStats)) as GuildStats;
  for (let i = 0; i <= level; i++) {
    const weakestGuildStat = getWeakestGuildStat(idealGuildStats, guild);
    idealGuildStats[guild].start[weakestGuildStat]++;
  }
  return idealGuildStats;
}

export function generateDarkboundStats(
  guildStats: GuildStats,
  campaigns: Campaigns
): DarkboundStats {
  const result = [];
  for (let i = 0; i < NUM_GUILD_LEVELS; i++) {
    result.push(
      Object.fromEntries(
        Object.keys(guildStats).map((key) => {
          const guild = key as Guild;
          const darkboundLevel =
            campaigns[guild]?.levels[i]?.darkboundLevel ?? 0;
          const darkboundLevelEasy =
            campaigns[guild]?.levels[i]?.darkboundLevelEasy ?? 0;
          const darkboundLevelHard =
            campaigns[guild]?.levels[i]?.darkboundLevelHard ?? 0;
          const easyStats = generateIdealGuildLevelStat(
            guildStats,
            guild,
            darkboundLevelEasy
          )[guild].start;
          const normalStats = generateIdealGuildLevelStat(
            guildStats,
            guild,
            darkboundLevel
          )[guild].start;
          const hardStats = generateIdealGuildLevelStat(
            guildStats,
            guild,
            darkboundLevelHard
          )[guild].start;
          return [
            guild,
            {
              easy: easyStats,
              normal: normalStats,
              hard: hardStats,
            },
          ];
        })
      )
    );
  }
  return result as DarkboundStats;
}

export function findDarkboundStatDiffs(
  darkboundStats: DarkboundStats,
  guild: Guild,
  levelBefore: number,
  levelAfter: number,
  difficulty: Difficulty = 'normal'
): Record<keyof GuildStat, number> {
  const result = Object.fromEntries(
    Object.keys(darkboundStats[0][guild]).map((stat) => [
      stat as keyof GuildStat,
      0,
    ])
  ) as Record<keyof GuildStat, number>;
  const statsBefore = darkboundStats[levelBefore][guild][difficulty];
  const statsAfter = darkboundStats[levelAfter][guild][difficulty];
  Object.keys(statsBefore).forEach((key) => {
    const stat = key as keyof GuildStat;
    result[stat] = statsAfter[stat] - statsBefore[stat];
  });
  return result;
}

export function parseCsv(str: string) {
  let csv = [],
    pointer = 0,
    c = null,
    quote = 0,
    col = '',
    row = [];
  while ((c = str.charAt(pointer))) {
    pointer++;
    if (c == '"') {
      quote++;
      // first and even numbered quotes are parsed, not used
      if (quote == 1 || quote % 2 == 0) {
        continue;
      }
    } else if ([',', '\r\n', '\n', '\r'].includes(c)) {
      // even quotes means a completed col and potential row
      if (quote % 2 == 0) {
        row.push(col);
        col = '';
        quote = 0;
        // if delimiter is not a comma, also a completed row
        if (c != ',') {
          // handle edge case of \r\n being two separate characters
          if (c == '\r' && str.charAt(pointer) == '\n') {
            pointer++;
          }
          csv.push(row);
          row = [];
        }
        continue;
      }
    }
    col += c;
  }
  return csv;
}

function parseArenaSize(value: string): ArenaSize {
  switch (value) {
    case 'Small':
      return 'smallArena';
    case 'Big':
      return 'bigArena';
    default:
    case 'Medium':
      return 'mediumArena';
  }
}

function parseUnlockable(value: string): Unlockable | null {
  switch (value.trim()) {
    case 'Sprint':
      return 'dash';
    case 'Special Attack':
      return 'specialAttack';
    case 'Small Arena':
      return 'smallArena';
    case 'Big Arena':
      return 'bigArena';
    case 'Medium Arena':
      return 'mediumArena';
    case 'Small Arena':
      return 'smallArena';
    case 'Archer Campaign':
      return 'Archer';
    case 'Assassin Campaign':
      return 'Assassin';
    case 'Barbarian Campaign':
      return 'Barbarian';
    case 'Knight Campaign':
      return 'Knight';
    case 'Priest Campaign':
      return 'Priest';
    case 'Wizard Campaign':
      return 'Wizard';
    case 'Guild Size 10':
      return 'guildSize10';
    case 'Guild Size 20':
      return 'guildSize20';
    case 'Guild Size 30':
      return 'guildSize30';
    case 'Guild Size 40':
      return 'guildSize40';
    case 'Guild Size 50':
      return 'guildSize50';
    case 'Guild Size 60':
      return 'guildSize60';
    case 'vFormation':
      return 'vFormation';
    case 'V Formation':
      return 'vFormation';
    case 'Circle Formation':
      return 'circleFormation';
    case 'Triangle Formation':
      return 'triangleFormation';
    case 'Snake Formation':
      return 'snakeFormation';
    case 'Crescent Formation':
      return 'crescentFormation';
    case 'Flank Formation':
      return 'flankFormation';
    case 'Hollow Triangle Formation':
      return 'hollowTriangleFormation';
    case 'Hollow Square Formation':
      return 'hollowSquareFormation';
    case 'Sky Dragon':
      return 'skyDragon';
    case 'Plant Dragon':
      return 'plantDragon';
    case 'Earth Dragon':
      return 'earthDragon';
    case 'Ice Dragon':
      return 'iceDragon';
    case 'Fire Dragon':
      return 'fireDragon';
    case 'Poison Dragon':
      return 'poisonDragon';
    case 'Shadow Dragon':
      return 'shadowDragon';
    default:
      return null;
  }
}

function parseStartingGuildSize(value: string): number {
  return value.toLowerCase().startsWith('inf') ? -1 : +(value || 0);
}

export async function fetchFromGoogleSheets() {
  const url =
    'https://sheets.googleapis.com/v4/spreadsheets/1_C2qvCdHeC9wuJgnzE26jcpZPwpWg6OKlXfSccCfme4/values/Campaign%20Story?key=AIzaSyAUBdQq0u8Adxmfjgfrm9OxTbXT075Lby0';
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json;
  } catch (error: any) {
    console.error(error.message);
  }
}

export function parseProgressionsFromCsv(csv: string, campaigns: Campaigns) {
  const data = parseCsv(csv);
  return parseProgressions(data, campaigns);
}

export function parseProgressions(data: string[][], campaigns: Campaigns) {
  const updatedCampaigns: Campaigns = JSON.parse(
    JSON.stringify(campaigns)
  ) as Campaigns;
  let levelIndex = 0;
  data.forEach((row) => {
    const [
      rawGuild,
      title,
      story,
      objective,
      arenaSize,
      startingGuildSizeArcher,
      startingGuildSizeAssassin,
      startingGuildSizeBarbarian,
      startingGuildSizeKnight,
      startingGuildSizePriest,
      startingGuildSizeWizard,
      startingDarkboundSizeArcher,
      startingDarkboundSizeAssassin,
      startingDarkboundSizeBarbarian,
      startingDarkboundSizeKnight,
      startingDarkboundSizePriest,
      startingDarkboundSizeWizard,
      allies,
      missionTier1,
      missionTier2,
      missionTier3,
      unlockables,
    ] = row;

    if (rawGuild.startsWith('Level')) {
      levelIndex = +rawGuild.replace('Level ', '') - 1;
    }

    if (GUILDS.includes(rawGuild as Guild)) {
      const guild = rawGuild as Guild;
      updatedCampaigns[guild].levels[levelIndex] = {
        ...updatedCampaigns[guild].levels[levelIndex],
        title,
        story,
        objective,
        arenaSize: parseArenaSize(arenaSize),
        startingGuildSizes: [
          parseStartingGuildSize(startingGuildSizeArcher),
          parseStartingGuildSize(startingGuildSizeAssassin),
          parseStartingGuildSize(startingGuildSizeBarbarian),
          parseStartingGuildSize(startingGuildSizeKnight),
          parseStartingGuildSize(startingGuildSizePriest),
          parseStartingGuildSize(startingGuildSizeWizard),
        ],
        startingDarkboundSizes: [
          parseStartingGuildSize(startingDarkboundSizeArcher),
          parseStartingGuildSize(startingDarkboundSizeAssassin),
          parseStartingGuildSize(startingDarkboundSizeBarbarian),
          parseStartingGuildSize(startingDarkboundSizeKnight),
          parseStartingGuildSize(startingDarkboundSizePriest),
          parseStartingGuildSize(startingDarkboundSizeWizard),
        ],
        allies: allies
          .split(',')
          .map((x) => x.trim())
          .filter((x) => Boolean(x)) as Guild[],
        missions: [
          {
            ...updatedCampaigns[guild].levels[levelIndex].missions[0],
            description: missionTier1,
            ...parseMission(missionTier1),
          },
          {
            ...updatedCampaigns[guild].levels[levelIndex].missions[1],
            description: missionTier2,
            ...parseMission(missionTier2),
          },
          {
            ...updatedCampaigns[guild].levels[levelIndex].missions[2],
            description: missionTier3,
            ...parseMission(missionTier3),
          },
        ],
        unlockables: unlockables
          .split(',')
          .map(parseUnlockable)
          .filter((x) => Boolean(x)) as Unlockable[],
      };
    }
  });
  return updatedCampaigns;
}

const missionRegex: Record<string, MissionCriteria> = {
  'Survive for (\\d+) minutes': 'surviveTime',
  'Survive with at least (\\d+) units': 'surviveUnits',
  'Fortress HP is more than (\\d+)%': 'fortressSurvive',
  'At least an ally unit survive': 'allySurvive',
  'All ally units survive': 'allAllySurvive',
  'Defeat the (\\w+) Dragon': 'defeatDragon',
  'Defeat (\\d+) darkbounds': 'defeatDarkbounds',
  'Defeat patrolling darkbounds': 'defeatDarkbounds',
  'Defeat all darkbounds': 'defeatDarkbounds',
  'Defeat (\\d+) Darkbounds while in formation': 'defeatDarkboundsFormation',
  'Defeat level within (\\d+) minute': 'defeatLevel',
  'Use special attack (\\d+) times': 'useSpecial',
  "Didn't get spotted at all": 'avoidAttention',
  "Didn't lose any units": 'avoidKill',
  "Didn't take any damage": 'avoidDamage',
};

function parseMission(
  description: string
): Pick<CampaignMission, 'criteria' | 'criteriaValue'> {
  let criteria: MissionCriteria = 'surviveTime';
  let criteriaValue: string | number = 0;

  Object.keys(missionRegex).forEach((key) => {
    const missionCriteria = missionRegex[key];
    const matches = description.match(new RegExp(key, 'i'));
    if (matches) {
      criteria = missionCriteria;
      switch (missionCriteria) {
        case 'fortressSurvive':
          criteriaValue = +matches[1] / 100;
          break;

        case 'defeatDragon':
          break;

        default:
          if (matches.length > 1) {
            criteriaValue = +matches[1];
          }
          break;
      }
    }
  });

  return {
    criteria,
    criteriaValue,
  };
}

export function exportData(
  campaigns: Campaigns,
  guildStats: GuildStats,
  darkboundStats: DarkboundStats,
  guildProgressions: GuildProgressions
): ExportData {
  const campaignsExport: CampaignsExport = Object.fromEntries(
    Object.keys(campaigns).map((key) => {
      const guild = key as Guild;
      return [
        guild,
        campaigns[guild].levels.map(
          ({
            chapter,
            arenaSize,
            startingGuildSizes,
            startingDarkboundSizes,
            allies,
            darkboundLevel,
            darkboundLevelEasy,
            darkboundLevelHard,
            missions,
            unlockables,
          }) => ({
            chapter,
            arenaSize: ARENA_SIZES.indexOf(arenaSize),
            startingGuildSizes,
            startingDarkboundSizes,
            allies: allies.includes('All' as Guild)
              ? [0, 1, 2, 3, 4, 5]
              : allies.map((ally) => GUILDS.indexOf(ally)),
            darkboundLevel,
            darkboundLevelEasy,
            darkboundLevelHard,
            missions: missions.map(
              ({ criteria, criteriaValue, xpRewardFirst, xpReward }) => ({
                criteria: MISSION_CRITERIA.indexOf(criteria),
                criteriaValue,
                xpRewardFirst,
                xpReward,
              })
            ),
            unlockables: unlockables.map((unlockable) =>
              UNLOCKABLES.indexOf(unlockable)
            ),
          })
        ),
      ];
    })
  ) as unknown as CampaignsExport;
  return {
    guildStats,
    darkboundStats,
    campaigns: campaignsExport,
    guildProgressions,
  };
}
