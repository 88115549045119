export type Screen = 'campaign' | 'guild' | 'progression';
export type Guild =
  | 'Archer'
  | 'Assassin'
  | 'Barbarian'
  | 'Knight'
  | 'Priest'
  | 'Wizard';

export const GUILDS: Guild[] = [
  'Archer',
  'Assassin',
  'Barbarian',
  'Knight',
  'Priest',
  'Wizard',
];

export type GuildStat = {
  attackPoints: number;
  powerUpAttackPoints: number;
  defensePoints: number;
  powerUpDefensePoints: number;
  disciplinePoints: number;
  specialAttackPoints: number;
};

export type Difficulty = 'easy' | 'normal' | 'hard';

export type GuildStats = Record<Guild, Record<'start' | 'max', GuildStat>>;
export type DarkboundStats = Record<Guild, Record<Difficulty, GuildStat>>[];

export type Dragon =
  | 'fireDragon'
  | 'iceDragon'
  | 'plantDragon'
  | 'poisonDragon'
  | 'skyDragon'
  | 'earthDragon'
  | 'shadowDragon';

export type Formation =
  | 'vFormation'
  | 'circleFormation'
  | 'triangleFormation'
  | 'snakeFormation'
  | 'crescentFormation'
  | 'flankFormation'
  | 'hollowTriangleFormation'
  | 'hollowSquareFormation';

export type GuildSize =
  | 'guildSize10'
  | 'guildSize20'
  | 'guildSize30'
  | 'guildSize40'
  | 'guildSize50'
  | 'guildSize60';

export type ArenaSize = 'mediumArena' | 'smallArena' | 'bigArena';

export const ARENA_SIZES: ArenaSize[] = [
  'bigArena',
  'mediumArena',
  'smallArena',
];

export type Unlockable =
  | 'dash'
  | 'specialAttack'
  | ArenaSize
  | Dragon
  | Formation
  | GuildSize
  | Guild
  | 'goldArmor';

export const UNLOCKABLES: Unlockable[] = [
  'dash',
  'specialAttack',
  'mediumArena',
  'smallArena',
  'bigArena',
  'fireDragon',
  'iceDragon',
  'plantDragon',
  'poisonDragon',
  'skyDragon',
  'earthDragon',
  'shadowDragon',
  'vFormation',
  'circleFormation',
  'triangleFormation',
  'snakeFormation',
  'crescentFormation',
  'flankFormation',
  'hollowTriangleFormation',
  'hollowSquareFormation',
  'guildSize10',
  'guildSize20',
  'guildSize30',
  'guildSize40',
  'guildSize50',
  'guildSize60',
  'Archer',
  'Assassin',
  'Barbarian',
  'Knight',
  'Priest',
  'Wizard',
  'goldArmor',
];

export type Campaign = {
  id: string;
  guild: Guild;
  levels: CampaignLevel[];
};

export type Campaigns = Record<Guild, Campaign>;

export type StartingGuildSizes = Record<Guild, number>;

export type CampaignLevel = {
  id: string;
  chapter: number;
  title: string;
  objective: string;
  story: string;
  arenaSize: ArenaSize;
  startingGuildSizes: number[];
  startingDarkboundSizes: number[];
  allies: Guild[];
  darkboundLevel: number;
  darkboundLevelEasy: number;
  darkboundLevelHard: number;
  missions: CampaignMission[];
  unlockables: Unlockable[];
};

export type MissionCriteria =
  | 'surviveTime'
  | 'surviveUnits'
  | 'fortressSurvive'
  | 'allySurvive'
  | 'allAllySurvive'
  | 'defeatDragon'
  | 'defeatDarkbounds'
  | 'defeatDarkboundsFormation'
  | 'defeatLevel'
  | 'useSpecial'
  | 'avoidAttention'
  | 'avoidKill'
  | 'avoidDamage';

export const MISSION_CRITERIA: MissionCriteria[] = [
  'surviveTime',
  'surviveUnits',
  'fortressSurvive',
  'allySurvive',
  'allAllySurvive',
  'defeatDragon',
  'defeatDarkbounds',
  'defeatDarkboundsFormation',
  'defeatLevel',
  'useSpecial',
  'avoidAttention',
  'avoidKill',
  'avoidDamage',
];

export type CampaignMission = {
  id: string;
  description: string;
  criteria: MissionCriteria;
  criteriaValue: number;
  xpRewardFirst: number;
  xpReward: number;
};

export type CampaignLevelExport = Omit<
  CampaignLevel,
  | 'id'
  | 'title'
  | 'objective'
  | 'story'
  | 'missions'
  | 'unlockables'
  | 'allies'
  | 'arenaSize'
> & {
  arenaSize: number;
  missions: CampaignMissionExport[];
  unlockables: number[];
  allies: number[];
};
export type CampaignMissionExport = Omit<
  CampaignMission,
  'id' | 'description' | 'criteria'
> & {
  criteria: number;
};

export type CampaignDetailType = 'details' | 'missions' | 'unlockables';

export type MissionTier = 1 | 2 | 3;

export type GuildProgression = {
  xp: number;
  campaignLevel: number;
  grind: number;
  grindAvg: number;
  grindWorst: number;
  xpReward: number;
}[];

export type GuildProgressions = Record<Guild, GuildProgression>;

export type CampaignsExport = Record<Guild, CampaignLevelExport[]>;

export type ExportData = {
  guildStats: GuildStats;
  darkboundStats: DarkboundStats;
  campaigns: CampaignsExport;
  guildProgressions: GuildProgressions;
};
