import { v4 as uuid } from 'uuid';
import { Campaign, Guild } from '../common/types';
import { compound, roundToTens } from '../common/utils';
import { NUM_CAMPAIGN_LEVELS, NUM_GUILD_LEVELS } from '../common/constants';

export const defaultXpRewardConfig = {
  baseXp: 100,
  tierMultiplier: 2,
  grindMultiplier: 0.5,
  levelMultiplier: 1.5,
  grindCoefficient: 1.2,
};

export type XpRewardConfig = typeof defaultXpRewardConfig;

export const defaultCampaigns: Record<Guild, Campaign> = {
  Archer: generateCampaign('Archer', defaultXpRewardConfig),
  Assassin: generateCampaign('Assassin', defaultXpRewardConfig),
  Barbarian: generateCampaign('Barbarian', defaultXpRewardConfig),
  Knight: generateCampaign('Knight', defaultXpRewardConfig),
  Priest: generateCampaign('Priest', defaultXpRewardConfig),
  Wizard: generateCampaign('Wizard', defaultXpRewardConfig),
};

export function generateCampaign(
  guild: Guild,
  xpRewardConfig: XpRewardConfig
): Campaign {
  const numLevels = Array(NUM_CAMPAIGN_LEVELS).fill(0);
  return {
    id: uuid(),
    guild,
    levels: numLevels.map((_, index) => {
      const chapterIndex = Math.floor(index * 0.5);
      const xpRewardFirst =
        index === 0
          ? xpRewardConfig.baseXp
          : roundToTens(
              compound(
                xpRewardConfig.baseXp,
                xpRewardConfig.levelMultiplier,
                index
              )
            );
      return {
        id: uuid(),
        chapter: chapterIndex,
        title: '',
        objective: '',
        story: '',
        arenaSize: 'mediumArena',
        startingGuildSizes: [0, 0, 0, 0, 0, 0],
        startingDarkboundSizes: [0, 0, 0, 0, 0, 0],
        darkboundLevel: 0,
        darkboundLevelEasy: 0,
        darkboundLevelHard: 0,
        unlockables: [],
        allies: [],
        missions: [
          {
            id: uuid(),
            description: 'Mission 1',
            criteria: 'surviveTime',
            criteriaValue: 0,
            xpRewardFirst,
            xpReward: roundToTens(
              xpRewardFirst * xpRewardConfig.grindMultiplier
            ),
          },
          {
            id: uuid(),
            description: 'Mission 2',
            criteria: 'surviveTime',
            criteriaValue: 0,
            xpRewardFirst: xpRewardFirst * xpRewardConfig.tierMultiplier,
            xpReward: roundToTens(
              xpRewardFirst *
                xpRewardConfig.tierMultiplier *
                xpRewardConfig.grindMultiplier
            ),
          },
          {
            id: uuid(),
            description: 'Mission 3',
            criteria: 'surviveTime',
            criteriaValue: 0,
            xpRewardFirst: xpRewardFirst * xpRewardConfig.tierMultiplier * 2,
            xpReward: roundToTens(
              xpRewardFirst *
                xpRewardConfig.tierMultiplier *
                2 *
                xpRewardConfig.grindMultiplier
            ),
          },
        ],
      };
    }),
  };
}
