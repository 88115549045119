import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { Box, Flex, IconButton, TabNav, Text } from '@radix-ui/themes';
import {
  Cross2Icon,
  GearIcon,
  UpdateIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import * as Toast from '@radix-ui/react-toast';
import CampaignScreen from './screens/CampaignScreen';
import GuildScreen from './screens/GuildScreen';
import ProgressionScreen from './screens/ProgressionScreen';
import { useScreenStore } from './store/ScreenStore';
import ConfigSidebar from './components/ConfigSidebar';
import { useConfigStore } from './store/ConfigStore';
import { exportData, fetchFromGoogleSheets } from './common/utils';

function Screens() {
  const activeScreen = useScreenStore((state) => state.activeScreen);

  switch (activeScreen) {
    case 'campaign':
      return <CampaignScreen />;

    case 'guild':
      return <GuildScreen />;

    case 'progression':
      return <ProgressionScreen />;

    default:
      return null;
  }
}

function getLocalProgressions() {
  const v = localStorage.getItem('gng_progressions');
  return v ? JSON.parse(v) : null;
}

function getLastSync() {
  const v = localStorage.getItem('gng_progressions_lastsaved');
  return v ? +v : -1;
}

function saveLocalProgressions(data: string[][]) {
  const now = Date.now();
  localStorage.setItem('gng_progressions_lastsaved', String(now));
  localStorage.setItem('gng_progressions', JSON.stringify(data));
  return now;
}

export default function App() {
  const activeScreen = useScreenStore((state) => state.activeScreen);
  const isConfigVisible = useScreenStore((state) => state.isConfigVisible);
  const showCampaignScreen = useScreenStore(
    (state) => state.showCampaignScreen
  );
  const showGuildScreen = useScreenStore((state) => state.showGuildScreen);
  const showProgressionScreen = useScreenStore(
    (state) => state.showProgressionScreen
  );
  const toggleConfig = useScreenStore((state) => state.toggleConfig);
  const updateProgressions = useConfigStore(
    (state) => state.updateProgressions
  );
  const campaigns = useConfigStore((state) => state.campaigns);
  const guildStats = useConfigStore((state) => state.guildStats);
  const darkboundStats = useConfigStore((state) => state.darkboundStats);
  const guildProgressions = useConfigStore((state) => state.guildProgressions);
  const [lastSync, setLastSync] = useState(getLastSync());
  const [toastOpen, setToastOpen] = useState(false);
  const toastTitleRef = useRef('');
  const toastMessageRef = useRef('');
  const toastTimerRef = useRef(0);

  useEffect(() => {
    const localData = getLocalProgressions();
    if (localData) {
      updateProgressions(localData);
    }
    navigator.permissions
      // @ts-ignore
      .query({ name: 'clipboard-write' })
      .then((result) => {
        if (result.state == 'granted' || result.state == 'prompt') {
          // do nothing
        } else {
          showToast(
            'Clipboard write blocked!',
            `This app couldn't access your clipboard.`
          );
        }
      });
  }, []);

  function showToast(title: string, message: string) {
    setToastOpen(false);
    window.clearTimeout(toastTimerRef.current);
    toastTimerRef.current = window.setTimeout(() => {
      toastTitleRef.current = title;
      toastMessageRef.current = message;
      setToastOpen(true);
    }, 100);
  }

  async function syncProgressions() {
    const remoteData = await fetchFromGoogleSheets();
    try {
      updateProgressions(remoteData.values as string[][]);
      setLastSync(saveLocalProgressions(remoteData.values));
      showToast(
        'Sync success!',
        'Campaign synced with Google sheets successfully.'
      );
    } catch (error: any) {
      console.error(error);
    }
  }

  async function copyDataToClipboard() {
    try {
      await navigator.clipboard.writeText(
        JSON.stringify(
          exportData(campaigns, guildStats, darkboundStats, guildProgressions)
        )
      );
      showToast(
        'Export success!',
        'The data copied to your clipboard successfully.'
      );
    } catch (error: any) {
      showToast('Export failed!', 'Failed to copy data to your clipboard.');
    }
  }

  return (
    <Toast.Provider swipeDirection="right">
      <div className="App">
        <Flex direction="row" gap="2">
          <Flex direction="column" className="mainColumn">
            <TabNav.Root>
              <TabNav.Link
                active={activeScreen === 'campaign'}
                onClick={showCampaignScreen}
              >
                Campaign
              </TabNav.Link>
              <TabNav.Link
                active={activeScreen === 'progression'}
                onClick={showProgressionScreen}
              >
                Progression
              </TabNav.Link>
              <TabNav.Link
                active={activeScreen === 'guild'}
                onClick={showGuildScreen}
              >
                Guild
              </TabNav.Link>
              {activeScreen !== 'guild' && (
                <Flex
                  flexGrow="1"
                  gap="3"
                  justify="end"
                  align="center"
                  className="navActions"
                >
                  <Text size="1">
                    Last synced {DateTime.fromMillis(lastSync).toRelative()}
                  </Text>
                  <Flex gap="1" align="center">
                    <IconButton onClick={syncProgressions}>
                      <UpdateIcon width="18" height="18" />
                    </IconButton>
                    <IconButton onClick={copyDataToClipboard}>
                      <UploadIcon width="18" height="18" />
                    </IconButton>
                    <IconButton onClick={toggleConfig}>
                      <GearIcon width="18" height="18" />
                    </IconButton>
                  </Flex>
                </Flex>
              )}
            </TabNav.Root>
            <Box style={{ padding: 8 }}>
              <Screens />
            </Box>
          </Flex>
          {isConfigVisible && activeScreen !== 'guild' && <ConfigSidebar />}
        </Flex>
      </div>
      <Toast.Root
        duration={3000}
        className="ToastRoot"
        open={toastOpen}
        onOpenChange={setToastOpen}
      >
        <Toast.Title className="ToastTitle">
          {toastTitleRef.current}
        </Toast.Title>
        <Toast.Description asChild>
          <time className="ToastDescription">{toastMessageRef.current}</time>
        </Toast.Description>
        <Toast.Action className="ToastAction" asChild altText="Close toast">
          <Cross2Icon width="18" height="18" />
        </Toast.Action>
      </Toast.Root>
      <Toast.Viewport className="ToastViewport" />
    </Toast.Provider>
  );
}
