import { Fragment } from 'react';
import { Table, TextField } from '@radix-ui/themes';
import { GUILDS, GUILD_STATS } from '../common/constants';
import { Guild, GuildStat } from '../common/types';
import { useConfigStore } from '../store/ConfigStore';

export default function GuildStatsTable() {
  const guildStats = useConfigStore((state) => state.guildStats);
  const setStartGuildStat = useConfigStore((state) => state.setStartGuildStat);
  const setMaxGuildStat = useConfigStore((state) => state.setMaxGuildStat);

  function handleStartStatChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    guild: Guild,
    stat: keyof GuildStat
  ) {
    const { value } = event.target;
    setStartGuildStat(guild, stat, +value);
  }

  function handleMaxStatChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    guild: Guild,
    stat: keyof GuildStat
  ) {
    const { name, value } = event.target;
    setMaxGuildStat(guild, stat, +value);
  }

  return (
    <Table.Root size="1">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell rowSpan={2}>
            Guild Stat
          </Table.ColumnHeaderCell>
          {GUILDS.map((guild) => (
            <Table.ColumnHeaderCell
              key={`${guild}_name`}
              colSpan={3}
              align="center"
            >
              {guild}
            </Table.ColumnHeaderCell>
          ))}
        </Table.Row>
        <Table.Row>
          {GUILDS.map((guild) => (
            <Fragment key={`${guild}_header`}>
              <Table.ColumnHeaderCell key={`${guild}_start`} align="center">
                Start
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell key={`${guild}_max`} align="center">
                Max
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell key={`${guild}_diff`} align="center">
                Diff
              </Table.ColumnHeaderCell>
            </Fragment>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {GUILD_STATS.map((stat) => {
          return (
            <Table.Row>
              <Table.RowHeaderCell>{stat}</Table.RowHeaderCell>
              {GUILDS.map((guild) => {
                return (
                  <Fragment key={`${guild}_${stat}`}>
                    <Table.Cell align="center">
                      <TextField.Root
                        type="number"
                        size="1"
                        color="orange"
                        variant="soft"
                        value={guildStats[guild].start[stat]}
                        onChange={(event) =>
                          handleStartStatChange(event, guild, stat)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell align="center">
                      <TextField.Root
                        type="number"
                        size="1"
                        color="green"
                        variant="soft"
                        value={guildStats[guild].max[stat]}
                        onChange={(event) =>
                          handleMaxStatChange(event, guild, stat)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell align="center">
                      <TextField.Root
                        size="1"
                        variant="soft"
                        disabled
                        value={
                          guildStats[guild].max[stat] -
                          guildStats[guild].start[stat]
                        }
                      />
                    </Table.Cell>
                  </Fragment>
                );
              })}
            </Table.Row>
          );
        })}

        <Table.Row>
          <Table.RowHeaderCell>
            <b>Total Points</b>
          </Table.RowHeaderCell>
          {GUILDS.map((guild) => {
            return (
              <Fragment key={`${guild}_total`}>
                <Table.Cell align="center">
                  <TextField.Root
                    size="1"
                    variant="soft"
                    disabled
                    value={GUILD_STATS.reduce(
                      (sum, stat) => sum + guildStats[guild].start[stat],
                      0
                    )}
                  />
                </Table.Cell>
                <Table.Cell align="center">
                  <TextField.Root
                    size="1"
                    variant="soft"
                    disabled
                    value={GUILD_STATS.reduce(
                      (sum, stat) => sum + guildStats[guild].max[stat],
                      0
                    )}
                  />
                </Table.Cell>
                <Table.Cell align="center">
                  <TextField.Root
                    size="1"
                    variant="soft"
                    disabled
                    value={GUILD_STATS.reduce(
                      (sum, stat) =>
                        sum +
                        guildStats[guild].max[stat] -
                        guildStats[guild].start[stat],
                      0
                    )}
                  />
                </Table.Cell>
              </Fragment>
            );
          })}
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
}
