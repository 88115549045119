import { Fragment } from 'react';
import { Text, Table } from '@radix-ui/themes';
import { GUILDS, GUILD_STATS } from '../common/constants';
import { useConfigStore } from '../store/ConfigStore';
import { useScreenStore } from '../store/ScreenStore';
import { findDarkboundStatDiffs } from '../common/utils';

function renderDiffValue(value: number) {
  if (value > 0) {
    return (
      <Text size="1" color="green">
        {' '}
        (+{value})
      </Text>
    );
  }
  if (value < 0) {
    return (
      <Text size="1" color="red">
        {' '}
        ({value})
      </Text>
    );
  }
  return null;
}

export default function DarkboundStatsTable() {
  const darkboundStats = useConfigStore((state) => state.darkboundStats);
  const activeGuildLevel = useScreenStore((state) => state.activeGuildLevel);

  return (
    <Table.Root size="1">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Darkbound Stat</Table.ColumnHeaderCell>
          {GUILDS.map((guild) => (
            <Table.ColumnHeaderCell
              key={`${guild}_name`}
              colSpan={3}
              align="center"
            >
              {guild}
            </Table.ColumnHeaderCell>
          ))}
        </Table.Row>
        <Table.Row>
          <Table.RowHeaderCell>
            <Text size="1">
              (<b>E</b>asy | <b>N</b>ormal | <b>H</b>ard)
            </Text>
          </Table.RowHeaderCell>
          {GUILDS.map((guild) => (
            <Fragment key={`${guild}_header`}>
              <Table.ColumnHeaderCell
                key={`${guild}_start`}
                align="center"
                minWidth="60px"
              >
                E
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell
                key={`${guild}_max`}
                align="center"
                minWidth="60px"
              >
                N
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell
                key={`${guild}_diff`}
                align="center"
                minWidth="60px"
              >
                H
              </Table.ColumnHeaderCell>
            </Fragment>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {GUILD_STATS.map((stat) => {
          return (
            <Table.Row>
              <Table.RowHeaderCell>{stat}</Table.RowHeaderCell>
              {GUILDS.map((guild) => {
                const easyStatDiff = findDarkboundStatDiffs(
                  darkboundStats,
                  guild,
                  Math.max(0, activeGuildLevel - 1),
                  activeGuildLevel,
                  'easy'
                );
                const normalStatDiff = findDarkboundStatDiffs(
                  darkboundStats,
                  guild,
                  Math.max(0, activeGuildLevel - 1),
                  activeGuildLevel,
                  'normal'
                );
                const hardStatDiff = findDarkboundStatDiffs(
                  darkboundStats,
                  guild,
                  Math.max(0, activeGuildLevel - 1),
                  activeGuildLevel,
                  'hard'
                );
                return (
                  <Fragment key={`${guild}_${stat}`}>
                    <Table.Cell align="center">
                      <Text>
                        {darkboundStats[activeGuildLevel][guild].easy[stat]}
                        {renderDiffValue(easyStatDiff[stat])}
                      </Text>
                    </Table.Cell>
                    <Table.Cell align="center">
                      <Text>
                        {darkboundStats[activeGuildLevel][guild].normal[stat]}
                        {renderDiffValue(normalStatDiff[stat])}
                      </Text>
                    </Table.Cell>
                    <Table.Cell align="center">
                      <Text>
                        {darkboundStats[activeGuildLevel][guild].hard[stat]}
                        {renderDiffValue(hardStatDiff[stat])}
                      </Text>
                    </Table.Cell>
                  </Fragment>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
}
