import { Card, Flex, Slider, Text } from '@radix-ui/themes';
import GuildStatsTable from '../components/GuildStatsTable';
import DarkboundStatsTable from '../components/DarkboundStatsTable';
import { useScreenStore } from '../store/ScreenStore';
import { NUM_CAMPAIGN_LEVELS } from '../common/constants';

export default function GuildScreen() {
  const activeGuildLevel = useScreenStore((state) => state.activeGuildLevel);
  const setActiveGuildLevel = useScreenStore(
    (state) => state.setActiveGuildLevel
  );

  return (
    <Flex gap="2" direction="column">
      <Card>
        <GuildStatsTable />
      </Card>
      <Card>
        <Flex gap="3" align="center">
          <Flex flexShrink="0">
            <Text size="2">Campaign level:</Text>
          </Flex>
          <Slider
            min={0}
            max={NUM_CAMPAIGN_LEVELS - 1}
            defaultValue={[activeGuildLevel]}
            onValueChange={([value]) => setActiveGuildLevel(value)}
          />
          <Text>{activeGuildLevel + 1}</Text>
        </Flex>
      </Card>
      <Card>
        <DarkboundStatsTable />
      </Card>
    </Flex>
  );
}
