import { create } from 'zustand';
import type {
  CampaignLevel,
  Campaigns,
  DarkboundStats,
  Guild,
  GuildProgressions,
  GuildStat,
  GuildStats,
} from '../common/types';
import {
  findGuildByLevelId,
  generateDarkboundStats,
  parseProgressions,
  updateCampaignProgressions,
  updateDarkboundLevels,
} from '../common/utils';
import {
  defaultCampaigns as rawDefaultCampaigns,
  defaultXpRewardConfig,
  XpRewardConfig,
} from './defaultCampaigns';
import {
  defaultGuildStats,
  defaultGuildProgressions,
  generateGuildProgressions,
} from './defaultGuildStats';

type ConfigStoreState = {
  xpRewardConfig: XpRewardConfig;
  guildStats: GuildStats;
  darkboundStats: DarkboundStats;
  campaigns: Campaigns;
  guildProgressions: GuildProgressions;
  lastCampaignUpdatedAt: number;
  setXpRewardConfig: (xpRewardConfig: XpRewardConfig) => void;
  setStartGuildStat: (
    guild: Guild,
    stat: keyof GuildStat,
    value: number
  ) => void;
  setMaxGuildStat: (guild: Guild, stat: keyof GuildStat, value: number) => void;
  updateCampaignLevel: (level: CampaignLevel) => void;
  updateProgressions: (data: string[][]) => void;
};

const defaultCampaigns = updateDarkboundLevels(
  rawDefaultCampaigns,
  defaultGuildProgressions
);
const defaultDarkboundStats = generateDarkboundStats(
  defaultGuildStats,
  defaultCampaigns
);

export const useConfigStore = create<ConfigStoreState>((set) => ({
  xpRewardConfig: defaultXpRewardConfig,
  guildStats: defaultGuildStats,
  darkboundStats: defaultDarkboundStats,
  campaigns: defaultCampaigns,
  lastCampaignUpdatedAt: 0,
  guildProgressions: defaultGuildProgressions,
  setXpRewardConfig: (xpRewardConfig: XpRewardConfig) =>
    set((state: ConfigStoreState) => {
      const updatedCampaigns = updateCampaignProgressions(
        state.campaigns,
        xpRewardConfig
      );
      const guildProgressions = generateGuildProgressions(
        state.guildStats,
        updatedCampaigns,
        xpRewardConfig
      );
      return {
        ...state,
        xpRewardConfig,
        guildProgressions,
        campaigns: updateDarkboundLevels(updatedCampaigns, guildProgressions),
      };
    }),
  setStartGuildStat: (guild: Guild, stat: keyof GuildStat, value: number) =>
    set((state: ConfigStoreState) => {
      const updatedGuildStats = {
        ...state.guildStats,
        [guild]: {
          ...state.guildStats[guild],
          start: {
            ...state.guildStats[guild].start,
            [stat]: value,
          },
        },
      };
      return { guildStats: updatedGuildStats };
    }),
  setMaxGuildStat: (guild: Guild, stat: keyof GuildStat, value: number) =>
    set((state: ConfigStoreState) => {
      const updatedGuildStats = {
        ...state.guildStats,
        [guild]: {
          ...state.guildStats[guild],
          max: {
            ...state.guildStats[guild].max,
            [stat]: value,
          },
        },
      };
      return { guildStats: updatedGuildStats };
    }),
  updateCampaignLevel: (level: CampaignLevel) =>
    set((state: ConfigStoreState) => {
      const guild = findGuildByLevelId(state.campaigns, level.id);
      if (!guild) return state;

      const campaigns = state.campaigns[guild];
      if (!campaigns) return state;

      const levelIndex = state.campaigns[guild].levels.findIndex(
        (campaignLevel) => level.id === campaignLevel.id
      );
      if (levelIndex === -1) return state;

      let updatedLevels = state.campaigns[guild].levels.concat();
      updatedLevels.splice(levelIndex, 1, level);

      const updatedCampaign = {
        ...state.campaigns[guild],
        levels: updatedLevels,
      };

      return {
        lastCampaignUpdatedAt: Date.now(),
        campaigns: {
          ...state.campaigns,
          [guild]: updatedCampaign,
        },
      };
    }),
  updateProgressions: (data: string[][]) =>
    set((state: ConfigStoreState) => {
      return {
        campaigns: parseProgressions(data, state.campaigns),
      };
    }),
}));
