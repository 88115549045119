import { create } from 'zustand';
import type {
  Guild,
  Screen,
  CampaignDetailType,
  CampaignLevel,
  MissionTier,
  Unlockable,
  ArenaSize,
} from '../common/types';
import { GUILDS } from '../common/constants';

type ScreenStoreState = {
  isConfigVisible: boolean;
  activeScreen: Screen;
  activeGuild: Guild;
  activeCampaignDetailTypeByLevelId: Record<string, CampaignDetailType>;
  editingCampaignLevel: CampaignLevel | undefined;
  activeGuildLevel: number;
  toggleConfig: () => void;
  showGuildScreen: () => void;
  showCampaignScreen: () => void;
  showProgressionScreen: () => void;
  setActiveGuildLevel: (level: number) => void;
  setActiveGuild: (guild: Guild) => void;
  setActiveCampaignDetailType: (
    levels: CampaignLevel[],
    rewardType: CampaignDetailType
  ) => void;
  setActiveCampaignDetailTypeByLevelId: (
    levelId: string,
    rewardType: CampaignDetailType
  ) => void;
  setEditingCampaignLevel: (level: CampaignLevel | undefined) => void;
  updateEditingCampaignLevelTitle: (title: string) => void;
  updateEditingCampaignLevelObjective: (objective: string) => void;
  updateEditingCampaignLevelStory: (story: string) => void;
  toggleEditingCampaignLevelAlly: (guild: Guild) => void;
  updateEditingCampaignLevelArenaSize: (arenaSize: ArenaSize) => void;
  updateEditingCampaignLevelStartingGuildSize: (
    guild: Guild,
    guildSize: number
  ) => void;
  updateEditingCampaignLevelStartingDarkboundSize: (
    guild: Guild,
    guildSize: number
  ) => void;
  updateEditingCampaignLevelMissionDescription: (
    tier: MissionTier,
    description: string
  ) => void;
  updateEditingCampaignLevelMissionXpReward: (
    tier: MissionTier,
    xpReward: number
  ) => void;
  updateEditingCampaignLevelMissionXpRewardFirst: (
    tier: MissionTier,
    xpRewardFirst: number
  ) => void;
  toggleEditingCampaignLevelMissionUnlockable: (unlockable: Unlockable) => void;
};

export const useScreenStore = create<ScreenStoreState>((set) => ({
  isConfigVisible: false,
  activeScreen: 'campaign',
  activeGuild: 'Archer',
  activeCampaignDetailType: 'xpTier1',
  activeCampaignDetailTypeByLevelId: {},
  editingCampaignLevel: undefined,
  activeGuildLevel: 0,
  toggleConfig: () =>
    set((state) => ({ isConfigVisible: !state.isConfigVisible })),
  showGuildScreen: () => set((state) => ({ activeScreen: 'guild' })),
  showCampaignScreen: () => set((state) => ({ activeScreen: 'campaign' })),
  showProgressionScreen: () =>
    set((state) => ({ activeScreen: 'progression' })),
  setActiveGuildLevel: (level: number) =>
    set((state) => ({ activeGuildLevel: level })),
  setActiveGuild: (guild: Guild) => set((state) => ({ activeGuild: guild })),
  setActiveCampaignDetailType: (
    levels: CampaignLevel[],
    rewardType: CampaignDetailType
  ) =>
    set((state) => {
      return {
        activeCampaignDetailTypeByLevelId: levels.reduce(
          (o, level) => ({
            ...o,
            [level.id]: rewardType,
          }),
          { ...state.activeCampaignDetailTypeByLevelId }
        ),
      };
    }),
  setActiveCampaignDetailTypeByLevelId: (
    levelId: string,
    rewardType: CampaignDetailType
  ) =>
    set((state) => ({
      activeCampaignDetailTypeByLevelId: {
        ...state.activeCampaignDetailTypeByLevelId,
        [levelId]: rewardType,
      },
    })),
  setEditingCampaignLevel: (level: CampaignLevel | undefined) =>
    set((state) => ({
      editingCampaignLevel: level ? { ...level } : undefined,
    })),
  updateEditingCampaignLevelTitle: (title: string) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          title,
        },
      };
    }),
  updateEditingCampaignLevelObjective: (objective: string) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          objective,
        },
      };
    }),
  updateEditingCampaignLevelStory: (story: string) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          story,
        },
      };
    }),
  updateEditingCampaignLevelArenaSize: (arenaSize: ArenaSize) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          arenaSize,
        },
      };
    }),
  toggleEditingCampaignLevelAlly: (guild: Guild) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const { allies } = state.editingCampaignLevel;
      const updatedAllies = allies.find((a) => a === guild)
        ? allies.filter((u) => u !== guild)
        : [...allies, guild];
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          allies: updatedAllies,
        },
      };
    }),
  updateEditingCampaignLevelStartingGuildSize: (
    guild: Guild,
    guildSize: number
  ) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const { startingGuildSizes } = state.editingCampaignLevel;
      const guildIndex = GUILDS.indexOf(guild);
      if (guildIndex < 0) return state;
      const updatedStartingGuildSizes = [...startingGuildSizes];
      updatedStartingGuildSizes[guildIndex] = guildSize;
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          startingGuildSizes: updatedStartingGuildSizes,
        },
      };
    }),
  updateEditingCampaignLevelStartingDarkboundSize: (
    guild: Guild,
    guildSize: number
  ) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const { startingDarkboundSizes } = state.editingCampaignLevel;
      const guildIndex = GUILDS.indexOf(guild);
      if (guildIndex < 0) return state;
      const updatedStartingDarkboundSizes = [...startingDarkboundSizes];
      updatedStartingDarkboundSizes[guildIndex] = guildSize;
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          startingDarkboundSizes: updatedStartingDarkboundSizes,
        },
      };
    }),
  updateEditingCampaignLevelMissionDescription: (
    tier: MissionTier,
    description: string
  ) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const updatedMissions = state.editingCampaignLevel.missions.concat();
      updatedMissions.splice(tier - 1, 1, {
        ...updatedMissions[tier - 1],
        description,
      });
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          missions: updatedMissions,
        },
      };
    }),
  updateEditingCampaignLevelMissionXpReward: (
    tier: MissionTier,
    xpReward: number
  ) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const updatedMissions = state.editingCampaignLevel.missions.concat();
      updatedMissions.splice(tier - 1, 1, {
        ...updatedMissions[tier - 1],
        xpReward,
      });
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          missions: updatedMissions,
        },
      };
    }),
  updateEditingCampaignLevelMissionXpRewardFirst: (
    tier: MissionTier,
    xpRewardFirst: number
  ) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const updatedMissions = state.editingCampaignLevel.missions.concat();
      updatedMissions.splice(tier - 1, 1, {
        ...updatedMissions[tier - 1],
        xpRewardFirst,
      });
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          missions: updatedMissions,
        },
      };
    }),
  toggleEditingCampaignLevelMissionUnlockable: (unlockable: Unlockable) =>
    set((state) => {
      if (!state.editingCampaignLevel) return state;
      const { unlockables } = state.editingCampaignLevel;
      const updatedUnlockables = unlockables.find((u) => u === unlockable)
        ? unlockables.filter((u) => u !== unlockable)
        : [...unlockables, unlockable];
      return {
        editingCampaignLevel: {
          ...state.editingCampaignLevel,
          unlockables: updatedUnlockables,
        },
      };
    }),
}));
