import { Guild, GuildStat } from './types';

export const GUILDS: Guild[] = [
  'Archer',
  'Assassin',
  'Barbarian',
  'Knight',
  'Priest',
  'Wizard',
];

export const GUILD_STATS: (keyof GuildStat)[] = [
  'attackPoints',
  'powerUpAttackPoints',
  'defensePoints',
  'powerUpDefensePoints',
  'disciplinePoints',
  'specialAttackPoints',
];

export const UNLOCKABLES = [
  'dash',
  'specialAttack',
  'smallArena',
  'bigArena',
  'fireDragon',
  'iceDragon',
  'plantDragon',
  'poisonDragon',
  'skyDragon',
  'earthDragon',
  'shadowDragon',
  'vFormation',
  'circleFormation',
  'triangleFormation',
  'snakeFormation',
  'crescentFormation',
  'flankFormation',
  'hollowTriangleFormation',
  'hollowSquareFormation',
  'guildSize10',
  'guildSize20',
  'guildSize30',
  'guildSize40',
  'guildSize50',
  'guildSize60',
  'goldArmor',
];

export const UNLOCKABLE_COLORS = {
  dash: 'green',
  specialAttack: 'green',
  smallArena: 'purple',
  bigArena: 'purple',
  fireDragon: 'yellow',
  iceDragon: 'yellow',
  plantDragon: 'yellow',
  poisonDragon: 'yellow',
  skyDragon: 'yellow',
  earthDragon: 'yellow',
  shadowDragon: 'yellow',
  vFormation: 'blue',
  circleFormation: 'blue',
  triangleFormation: 'blue',
  snakeFormation: 'blue',
  crescentFormation: 'blue',
  flankFormation: 'blue',
  hollowTriangleFormation: 'blue',
  hollowSquareFormation: 'blue',
  guildSize10: 'brown',
  guildSize20: 'brown',
  guildSize30: 'brown',
  guildSize40: 'brown',
  guildSize50: 'brown',
  guildSize60: 'brown',
  goldArmor: 'red',
};

export const NUM_GUILD_LEVELS = 35;
export const NUM_CAMPAIGN_LEVELS = 13;
