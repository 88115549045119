import { NUM_GUILD_LEVELS } from '../common/constants';
import {
  Campaigns,
  Guild,
  GuildProgressions,
  GuildStat,
  GuildStats,
} from '../common/types';
import { generateGuildProgression } from '../common/utils';
import {
  defaultCampaigns,
  defaultXpRewardConfig,
  XpRewardConfig,
} from './defaultCampaigns';

function toGuildStat(values: number[]): GuildStat {
  const [
    attackPoints,
    powerUpAttackPoints,
    defensePoints,
    powerUpDefensePoints,
    disciplinePoints,
    specialAttackPoints,
  ] = values;
  return {
    attackPoints,
    powerUpAttackPoints,
    defensePoints,
    powerUpDefensePoints,
    disciplinePoints,
    specialAttackPoints,
  };
}

export const defaultGuildStats: GuildStats = {
  Archer: {
    start: toGuildStat([5, 9, 7, 12, 5, 5]),
    max: toGuildStat([10, 15, 14, 19, 10, 10]),
  },
  Assassin: {
    start: toGuildStat([4, 6, 5, 7, 5, 5]),
    max: toGuildStat([8, 12, 11, 15, 10, 11]),
  },
  Barbarian: {
    start: toGuildStat([4, 10, 8, 14, 6, 13]),
    max: toGuildStat([10, 15, 16, 19, 10, 20]),
  },
  Knight: {
    start: toGuildStat([5, 8, 5, 8, 6, 8]),
    max: toGuildStat([10, 15, 10, 14, 10, 16]),
  },
  Priest: {
    start: toGuildStat([5, 7, 5, 7, 5, 5]),
    max: toGuildStat([10, 14, 10, 15, 10, 10]),
  },
  Wizard: {
    start: toGuildStat([5, 8, 4, 8, 5, 8]),
    max: toGuildStat([10, 15, 9, 14, 10, 15]),
  },
};

export function generateGuildProgressions(
  guildStats: GuildStats = defaultGuildStats,
  campaigns: Campaigns = defaultCampaigns,
  xpRewardConfig: XpRewardConfig = defaultXpRewardConfig
): GuildProgressions {
  return Object.fromEntries(
    Object.keys(guildStats).map((key) => [
      key as Guild,
      generateGuildProgression(
        NUM_GUILD_LEVELS,
        campaigns,
        key as Guild,
        xpRewardConfig
      ),
    ])
  ) as GuildProgressions;
}

export const defaultGuildProgressions: GuildProgressions =
  generateGuildProgressions();
