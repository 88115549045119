import {
  Button,
  Card,
  Flex,
  Heading,
  Slider,
  Text,
  TextField,
} from '@radix-ui/themes';
import { useMemo, useState } from 'react';
import { useConfigStore } from '../store/ConfigStore';
import {
  XpRewardConfig,
  defaultXpRewardConfig,
} from '../store/defaultCampaigns';

export default function ConfigSidebar() {
  const xpRewardConfig = useConfigStore((state) => state.xpRewardConfig);
  const setXpRewardConfig = useConfigStore((state) => state.setXpRewardConfig);
  const [updatedXpRewardConfig, setUpdatedXpRewardConfig] =
    useState<XpRewardConfig>(xpRewardConfig);

  const hasChanges = useMemo(
    () =>
      JSON.stringify(xpRewardConfig) !== JSON.stringify(updatedXpRewardConfig),
    [xpRewardConfig, updatedXpRewardConfig]
  );
  const canReset = useMemo(
    () =>
      JSON.stringify(xpRewardConfig) !== JSON.stringify(defaultXpRewardConfig),
    [xpRewardConfig, updatedXpRewardConfig]
  );

  return (
    <Card size="1" className="configSidebar">
      <Flex gap="3" direction="column">
        <Heading size="2">Campaign XP Rewards</Heading>
        {Object.keys(xpRewardConfig).map((key) => {
          const currentValue =
            updatedXpRewardConfig[key as keyof XpRewardConfig];
          return (
            <Flex key={key} direction="column" gap="1">
              <Text size="1">
                {key} ({currentValue})
              </Text>
              <Slider
                value={[currentValue]}
                step={key === 'baseXp' ? 10 : 0.1}
                min={key === 'baseXp' ? 100 : 0.1}
                max={key === 'baseXp' ? 1000 : 10}
                onValueChange={([value]) =>
                  setUpdatedXpRewardConfig({
                    ...updatedXpRewardConfig,
                    [key]: value,
                  })
                }
              />
            </Flex>
          );
        })}
        <Button
          disabled={!hasChanges}
          onClick={() => setXpRewardConfig(updatedXpRewardConfig)}
        >
          Save
        </Button>
        <Button
          disabled={!hasChanges}
          variant="outline"
          onClick={() => setUpdatedXpRewardConfig(xpRewardConfig)}
        >
          Cancel
        </Button>
        <Button
          disabled={!canReset}
          variant="outline"
          onClick={() => {
            setUpdatedXpRewardConfig(defaultXpRewardConfig);
            setXpRewardConfig(defaultXpRewardConfig);
          }}
        >
          Reset
        </Button>
      </Flex>
    </Card>
  );
}
