import { useMemo } from 'react';
import {
  Card,
  Flex,
  Grid,
  ScrollArea,
  SegmentedControl,
  Table,
  Text,
} from '@radix-ui/themes';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  ComposedChart,
} from 'recharts';
import {
  GUILDS,
  NUM_CAMPAIGN_LEVELS,
  NUM_GUILD_LEVELS,
} from '../common/constants';
import { formatNumber, getGuildStatSum } from '../common/utils';
import { useScreenStore } from '../store/ScreenStore';
import { useConfigStore } from '../store/ConfigStore';

const numGuildLevels = Array(NUM_GUILD_LEVELS).fill(0);
const numCampaignLevels = Array(NUM_CAMPAIGN_LEVELS).fill(0);

export default function ProgressionScreen() {
  const activeGuild = useScreenStore((state) => state.activeGuild);
  const setActiveGuild = useScreenStore((state) => state.setActiveGuild);
  const guildProgressions = useConfigStore((state) => state.guildProgressions);
  const darkboundStats = useConfigStore((state) => state.darkboundStats);
  const guildProgression = guildProgressions[activeGuild];
  const campaigns = useConfigStore((state) => state.campaigns);
  const campaign = campaigns[activeGuild];

  const maxGrind = useMemo(
    () =>
      guildProgression.reduce(
        (max, xp) => Math.max(max, Math.max(xp.grindAvg, xp.grindWorst)),
        0
      ),
    [guildProgression]
  );

  const darkboundChartData = useMemo(
    () =>
      numGuildLevels.map((level, levelIndex) => ({
        levelIndex: levelIndex + 1,
        normal:
          campaign.levels[guildProgression[levelIndex].campaignLevel]
            .darkboundLevel + 1 ?? 1,
        easy:
          campaign.levels[guildProgression[levelIndex].campaignLevel]
            .darkboundLevelEasy + 1 ?? 1,
        hard:
          campaign.levels[guildProgression[levelIndex].campaignLevel]
            .darkboundLevelHard + 1 ?? 1,
      })),
    [numGuildLevels, guildProgression]
  );

  const xpProgressionChartData = useMemo(
    () =>
      numGuildLevels.map((level, levelIndex) => ({
        levelIndex: levelIndex + 1,
        xp: guildProgression?.[levelIndex]?.xp ?? 0,
      })),
    [numGuildLevels, guildProgression]
  );

  const levelProgressionChartData = useMemo(
    () =>
      numGuildLevels.map((level, levelIndex) => ({
        levelIndex: levelIndex + 1,
        campaignLevel: guildProgression?.[levelIndex]?.campaignLevel + 1 ?? 1,
      })),
    [numGuildLevels, guildProgression]
  );

  const levelGrindChartData = useMemo(
    () =>
      numGuildLevels.map((level, levelIndex) => ({
        levelIndex: levelIndex + 1,
        avg: guildProgression?.[levelIndex]?.grindAvg ?? 0,
        worst: guildProgression?.[levelIndex]?.grindWorst ?? 0,
      })),
    [numGuildLevels, guildProgression]
  );

  const playerVsDarkboundChartData = useMemo(() => {
    return numCampaignLevels.map((level, levelIndex) => ({
      levelIndex: levelIndex + 1,
      player: getGuildStatSum(darkboundStats[levelIndex][activeGuild].normal),
      darkbound: [
        getGuildStatSum(darkboundStats[levelIndex][activeGuild].easy),
        getGuildStatSum(darkboundStats[levelIndex][activeGuild].hard),
      ],
    }));
  }, [numGuildLevels, guildProgression]);

  return (
    <Flex direction="column" gap="2">
      <SegmentedControl.Root defaultValue={activeGuild} className="topNav">
        {GUILDS.map((guild) => (
          <SegmentedControl.Item
            key={guild}
            value={guild}
            onClick={() => setActiveGuild(guild)}
          >
            {guild}
          </SegmentedControl.Item>
        ))}
      </SegmentedControl.Root>
      {guildProgression && (
        <Grid columns="1fr 3fr" gap="2">
          <Card>
            <ScrollArea
              className="guildProgressionTable"
              type="always"
              scrollbars="vertical"
              style={{ width: '100%' }}
            >
              <Table.Root size="1">
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>
                      <Text size="1">Guild</Text>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      <Text size="1">Req. XP</Text>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      <Text size="1">Level</Text>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      <Text size="1">Darkb.</Text>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      <Text size="1">Grind</Text>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      <Text size="1">Rwrd</Text>
                    </Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {numGuildLevels.map((level, levelIndex) => {
                    const xp = guildProgression?.[levelIndex]?.xp ?? 0;
                    const campaignLevel =
                      guildProgression?.[levelIndex]?.campaignLevel ?? 0;
                    const darkboundLevel =
                      campaign?.levels?.[campaignLevel]?.darkboundLevel ?? 0;
                    const grind = guildProgression?.[levelIndex]?.grind ?? 0;
                    const xpReward =
                      guildProgression?.[levelIndex]?.xpReward ?? 0;
                    return (
                      <Table.Row>
                        <Table.RowHeaderCell>
                          <Text size="1">{levelIndex + 1}</Text>
                        </Table.RowHeaderCell>
                        <Table.RowHeaderCell>
                          <Text size="1">{formatNumber(xp)}</Text>
                        </Table.RowHeaderCell>
                        <Table.RowHeaderCell>
                          <Text size="1">
                            {formatNumber(campaignLevel + 1)}
                          </Text>
                        </Table.RowHeaderCell>
                        <Table.RowHeaderCell>
                          <Text size="1">
                            {formatNumber(darkboundLevel + 1)}
                          </Text>
                        </Table.RowHeaderCell>
                        <Table.RowHeaderCell>
                          <Text size="1">{formatNumber(grind)}</Text>
                        </Table.RowHeaderCell>
                        <Table.RowHeaderCell>
                          <Text size="1">{formatNumber(xpReward)}</Text>
                        </Table.RowHeaderCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table.Root>
            </ScrollArea>
          </Card>
          <Flex direction="column" gap="2" flexGrow="1">
            <Grid gap="2" columns="1fr 1fr">
              <Flex direction="column" gap="2">
                <Card>
                  <ResponsiveContainer
                    width="100%"
                    height={300}
                    className="guildProgressionChart"
                  >
                    <LineChart data={darkboundChartData}>
                      <text
                        x={100}
                        y={20}
                        fill="black"
                        dominantBaseline="central"
                      >
                        <tspan fontSize="14">Darkbound Levels</tspan>
                      </text>
                      <Line
                        type="monotone"
                        dataKey="levelIndex"
                        stroke="#cccccc"
                        isAnimationActive={false}
                        dot={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="normal"
                        stroke="#8884d8"
                        isAnimationActive={false}
                        dot={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="easy"
                        stroke="#88d888"
                        isAnimationActive={false}
                        dot={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="hard"
                        stroke="#d88488"
                        isAnimationActive={false}
                        dot={false}
                      />
                      <Legend />
                      <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
                      <XAxis dataKey="levelIndex" fontSize={10} />
                      <YAxis
                        tickCount={NUM_GUILD_LEVELS}
                        fontSize={10}
                        domain={['dataMin', 'dataMax']}
                      />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
                <Card>
                  <ResponsiveContainer
                    width="100%"
                    height={300}
                    className="guildProgressionChart"
                  >
                    <ComposedChart data={playerVsDarkboundChartData}>
                      <text
                        x={100}
                        y={20}
                        fill="black"
                        dominantBaseline="central"
                      >
                        <tspan fontSize="14">Player vs Darkbound</tspan>
                      </text>
                      <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
                      <XAxis dataKey="levelIndex" fontSize={10} />
                      <YAxis domain={['dataMin', 'dataMax']} fontSize={10} />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="darkbound"
                        fill="#d88488"
                        isAnimationActive={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="player"
                        stroke="#413ea0"
                        isAnimationActive={false}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Card>
              </Flex>
              <Flex direction="column" gap="2" flexGrow="1">
                <Card>
                  <ResponsiveContainer
                    width="100%"
                    height={300}
                    className="guildProgressionChart"
                  >
                    <LineChart data={xpProgressionChartData}>
                      <text
                        x={100}
                        y={20}
                        fill="black"
                        dominantBaseline="central"
                      >
                        <tspan fontSize="14">XP Progression</tspan>
                      </text>
                      <Line
                        type="monotone"
                        dataKey="xp"
                        stroke="#8884d8"
                        isAnimationActive={false}
                        dot={false}
                      />
                      <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
                      <XAxis dataKey="levelIndex" fontSize={10} />
                      <YAxis
                        tickCount={20}
                        fontSize={10}
                        tickFormatter={formatNumber}
                      />
                      <Tooltip formatter={(value) => formatNumber(+value)} />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
                <Card>
                  <ResponsiveContainer
                    width="100%"
                    height={300}
                    className="guildProgressionChart"
                  >
                    <LineChart data={levelProgressionChartData}>
                      <text
                        x={100}
                        y={20}
                        fill="black"
                        dominantBaseline="central"
                      >
                        <tspan fontSize="14">Campaign Progression</tspan>
                      </text>
                      <Line
                        type="monotone"
                        dataKey="campaignLevel"
                        stroke="#82ca9d"
                        isAnimationActive={false}
                        dot={false}
                      />
                      <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
                      <XAxis dataKey="levelIndex" fontSize={10} />
                      <YAxis
                        tickCount={campaign?.levels?.length - 1}
                        domain={['dataMin', 'dataMax']}
                        fontSize={10}
                      />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Flex>
            </Grid>
            <Card>
              <ResponsiveContainer
                width="100%"
                height={300}
                className="guildProgressionChart"
              >
                <BarChart data={levelGrindChartData}>
                  <text x={100} y={20} fill="black" dominantBaseline="central">
                    <tspan fontSize="14">Campaign Grind</tspan>
                  </text>
                  <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
                  <XAxis dataKey="levelIndex" fontSize={10} />
                  <YAxis
                    tickCount={maxGrind + 1}
                    domain={['dataMin', 'dataMax']}
                    fontSize={10}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="avg" fill="#8884d8" isAnimationActive={false} />
                  <Bar
                    dataKey="worst"
                    fill="#82ca9d"
                    isAnimationActive={false}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Flex>
        </Grid>
      )}
    </Flex>
  );
}
