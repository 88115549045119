import {
  Card,
  Flex,
  Text,
  SegmentedControl,
  Grid,
  Heading,
  IconButton,
  TextField,
  TextArea,
  CheckboxCards,
  Box,
  Badge,
  DataList,
  Separator,
  Table,
  Switch,
} from '@radix-ui/themes';
import * as Label from '@radix-ui/react-label';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { CheckIcon, Cross2Icon, Pencil2Icon } from '@radix-ui/react-icons';
import { GUILDS, UNLOCKABLES, UNLOCKABLE_COLORS } from '../common/constants';
import type { Unlockable, CampaignLevel, MissionTier } from '../common/types';
import { useConfigStore } from '../store/ConfigStore';
import { useScreenStore } from '../store/ScreenStore';

function sortByUnlockable(a: Unlockable, b: Unlockable) {
  return UNLOCKABLES.indexOf(a) - UNLOCKABLES.indexOf(b);
}

export default function CampaignScreen() {
  const activeGuild = useScreenStore((state) => state.activeGuild);
  const activeCampaignDetailTypeByLevelId = useScreenStore(
    (state) => state.activeCampaignDetailTypeByLevelId
  );
  const editingCampaignLevel = useScreenStore(
    (state) => state.editingCampaignLevel
  );
  const setActiveGuild = useScreenStore((state) => state.setActiveGuild);
  const setActiveCampaignDetailTypeByLevelId = useScreenStore(
    (state) => state.setActiveCampaignDetailTypeByLevelId
  );
  const setActiveCampaignDetailType = useScreenStore(
    (state) => state.setActiveCampaignDetailType
  );
  const setEditingCampaignLevel = useScreenStore(
    (state) => state.setEditingCampaignLevel
  );
  const updateEditingCampaignLevelTitle = useScreenStore(
    (state) => state.updateEditingCampaignLevelTitle
  );
  const updateEditingCampaignLevelObjective = useScreenStore(
    (state) => state.updateEditingCampaignLevelObjective
  );
  const updateEditingCampaignLevelStory = useScreenStore(
    (state) => state.updateEditingCampaignLevelStory
  );
  const updateEditingCampaignLevelArenaSize = useScreenStore(
    (state) => state.updateEditingCampaignLevelArenaSize
  );
  const toggleEditingCampaignLevelAlly = useScreenStore(
    (state) => state.toggleEditingCampaignLevelAlly
  );
  const updateEditingCampaignLevelStartingGuildSize = useScreenStore(
    (state) => state.updateEditingCampaignLevelStartingGuildSize
  );
  const updateEditingCampaignLevelStartingDarkboundSize = useScreenStore(
    (state) => state.updateEditingCampaignLevelStartingDarkboundSize
  );
  const updateEditingCampaignLevelMissionDescription = useScreenStore(
    (state) => state.updateEditingCampaignLevelMissionDescription
  );
  const updateEditingCampaignLevelMissionXpRewardFirst = useScreenStore(
    (state) => state.updateEditingCampaignLevelMissionXpRewardFirst
  );
  const updateEditingCampaignLevelMissionXpReward = useScreenStore(
    (state) => state.updateEditingCampaignLevelMissionXpReward
  );
  const toggleEditingCampaignLevelMissionUnlockable = useScreenStore(
    (state) => state.toggleEditingCampaignLevelMissionUnlockable
  );
  const updateCampaignLevel = useConfigStore(
    (state) => state.updateCampaignLevel
  );
  const campaigns = useConfigStore((state) => state.campaigns);
  const campaign = campaigns[activeGuild];

  function renderReward(level: CampaignLevel) {
    const isEditing = level.id === editingCampaignLevel?.id;
    const activeCampaignDetailType =
      activeCampaignDetailTypeByLevelId[level.id];

    switch (activeCampaignDetailType) {
      default:
      case 'details':
        return isEditing ? (
          <Flex direction="column" gap="4">
            <Flex direction="column" gap="2" align="start">
              <Label.Root htmlFor="arenaSize">
                <Text size="2">Arena size</Text>
              </Label.Root>
              <ToggleGroup.Root
                id="arenaSize"
                aria-label="Arena size"
                className="ToggleGroup"
                type="single"
                defaultValue={editingCampaignLevel.arenaSize}
              >
                <ToggleGroup.Item
                  className="ToggleGroupItem"
                  value="smallArena"
                  aria-label="Small"
                  onClick={() =>
                    updateEditingCampaignLevelArenaSize('smallArena')
                  }
                >
                  <Text size="1">Small</Text>
                </ToggleGroup.Item>
                <ToggleGroup.Item
                  className="ToggleGroupItem"
                  value="mediumArena"
                  aria-label="Medium"
                  onClick={() =>
                    updateEditingCampaignLevelArenaSize('mediumArena')
                  }
                >
                  <Text size="1">Medium</Text>
                </ToggleGroup.Item>
                <ToggleGroup.Item
                  className="ToggleGroupItem"
                  value="bigArena"
                  aria-label="Big"
                  onClick={() =>
                    updateEditingCampaignLevelArenaSize('bigArena')
                  }
                >
                  <Text size="1">Big</Text>
                </ToggleGroup.Item>
              </ToggleGroup.Root>
            </Flex>
            <Table.Root size="1">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell rowSpan={2}>
                    Guild
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell rowSpan={2}>
                    Allies
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell rowSpan={2}>
                    Starting Guilds
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell rowSpan={2}>
                    Starting Darkbounds
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {GUILDS.map((guild, guildIndex) => (
                  <Table.Row key={guild}>
                    <Table.Cell>
                      <Text size="2">{guild}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Switch
                        checked={editingCampaignLevel.allies.includes(guild)}
                        onClick={(event) =>
                          toggleEditingCampaignLevelAlly(guild)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField.Root
                        type="number"
                        size="1"
                        variant="soft"
                        value={
                          editingCampaignLevel.startingGuildSizes[guildIndex]
                        }
                        onChange={(event) => {
                          updateEditingCampaignLevelStartingGuildSize(
                            GUILDS[guildIndex],
                            +event.target.value
                          );
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField.Root
                        type="number"
                        size="1"
                        variant="soft"
                        value={
                          editingCampaignLevel.startingDarkboundSizes[
                            guildIndex
                          ]
                        }
                        onChange={(event) => {
                          updateEditingCampaignLevelStartingDarkboundSize(
                            GUILDS[guildIndex],
                            +event.target.value
                          );
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Flex>
        ) : (
          <Flex direction="column" gap="4">
            <Flex direction="column" gap="2" align="start">
              <Text size="2">Arena size</Text>
              <Badge
                color={
                  level.arenaSize === 'smallArena'
                    ? 'green'
                    : level.arenaSize === 'bigArena'
                    ? 'red'
                    : 'blue'
                }
              >
                {level.arenaSize.replace('Arena', '')}
              </Badge>
            </Flex>
            <Grid columns="1fr 1fr 1fr" gap="4">
              {level.allies.length > 0 && (
                <Flex direction="column" gap="2">
                  <Text size="2">Allies</Text>
                  <Flex direction="column" gap="1" align="start">
                    {level.allies.map((ally) => (
                      <Badge key={`level_ally_${ally}`} color="gray">
                        {ally}
                      </Badge>
                    ))}
                  </Flex>
                </Flex>
              )}
              {level.startingGuildSizes.some((s) => s !== 0) && (
                <Flex direction="column" gap="2">
                  <Text size="2">Starting Guilds</Text>
                  <Card>
                    <DataList.Root>
                      {level.startingGuildSizes.map((guildSize, guildIndex) =>
                        guildSize ? (
                          <DataList.Item
                            key={`level_guildSize_${GUILDS[guildIndex]}`}
                            align="center"
                          >
                            <DataList.Label minWidth="60px">
                              <Text size="1">{GUILDS[guildIndex]}</Text>
                            </DataList.Label>
                            <DataList.Value className="justifyContentEnd">
                              <Badge color="jade" variant="soft" radius="full">
                                {guildSize}
                              </Badge>
                            </DataList.Value>
                          </DataList.Item>
                        ) : null
                      )}
                    </DataList.Root>
                  </Card>
                </Flex>
              )}
              {level.startingDarkboundSizes.some((s) => s !== 0) && (
                <Flex direction="column" gap="2">
                  <Text size="2">Starting Darkbounds</Text>
                  <Card>
                    <DataList.Root>
                      {level.startingDarkboundSizes.map(
                        (guildSize, guildIndex) =>
                          guildSize ? (
                            <DataList.Item
                              key={`level_guildSize_${GUILDS[guildIndex]}`}
                              align="center"
                            >
                              <DataList.Label minWidth="60px">
                                <Text size="1">{GUILDS[guildIndex]}</Text>
                              </DataList.Label>
                              <DataList.Value className="justifyContentEnd">
                                <Badge
                                  color={guildSize >= 0 ? 'green' : 'red'}
                                  variant="soft"
                                  radius="full"
                                >
                                  {guildSize >= 0 ? guildSize : 'infinite'}
                                </Badge>
                              </DataList.Value>
                            </DataList.Item>
                          ) : null
                      )}
                    </DataList.Root>
                  </Card>
                </Flex>
              )}
            </Grid>
          </Flex>
        );
      case 'missions':
        return isEditing
          ? editingCampaignLevel.missions.map((mission, index) => (
              <Flex
                key={`mission_${editingCampaignLevel?.id}_${index}`}
                direction="column"
                gap="2"
              >
                <Flex gap="3">
                  <Flex gap="1" align="center">
                    <Text size="1">1st XP reward:</Text>
                    <TextField.Root
                      disabled
                      type="number"
                      size="1"
                      variant="soft"
                      value={mission.xpRewardFirst}
                      onChange={(event) => {
                        updateEditingCampaignLevelMissionXpRewardFirst(
                          (index + 1) as MissionTier,
                          +event.target.value
                        );
                      }}
                    />
                  </Flex>
                  <Flex gap="1" align="center">
                    <Text size="1">Grind XP reward:</Text>
                    <TextField.Root
                      disabled
                      type="number"
                      size="1"
                      variant="soft"
                      value={mission.xpReward}
                      onChange={(event) => {
                        updateEditingCampaignLevelMissionXpReward(
                          (index + 1) as MissionTier,
                          +event.target.value
                        );
                      }}
                    />
                  </Flex>
                </Flex>
                <TextArea
                  placeholder={`Tier ${index + 1} mission description...`}
                  variant="soft"
                  value={mission.description}
                  rows={2}
                  onChange={(event) => {
                    updateEditingCampaignLevelMissionDescription(
                      (index + 1) as MissionTier,
                      event.target.value
                    );
                  }}
                />
              </Flex>
            ))
          : level.missions.map((mission, index) => (
              <Flex
                key={`mission_${editingCampaignLevel?.id}_${index}`}
                direction="column"
                gap="1"
              >
                <Flex direction="row" gap="1">
                  <Badge
                    color={
                      index === 0 ? 'green' : index === 1 ? 'orange' : 'purple'
                    }
                  >
                    Tier {index + 1} - {mission.xpRewardFirst} /{' '}
                    {mission.xpReward} XP
                  </Badge>
                  <Badge color="gray">
                    {mission.criteria} - {mission.criteriaValue}
                  </Badge>
                </Flex>
                <Text size="1">{mission.description}</Text>
              </Flex>
            ));
      case 'unlockables':
        return isEditing ? (
          <CheckboxCards.Root
            size="1"
            gap="1"
            defaultValue={editingCampaignLevel.unlockables}
            columns={{ initial: '1', sm: '3' }}
          >
            {UNLOCKABLES.map((unlockable) => (
              <CheckboxCards.Item
                key={unlockable}
                value={unlockable}
                onClick={(event) =>
                  toggleEditingCampaignLevelMissionUnlockable(
                    // @ts-ignore
                    event.target.value
                  )
                }
              >
                <Flex direction="column" width="100%">
                  <Text size="1">{unlockable}</Text>
                </Flex>
              </CheckboxCards.Item>
            ))}
          </CheckboxCards.Root>
        ) : (
          <Flex gap="1" wrap="wrap">
            {level.unlockables.sort(sortByUnlockable).map((unlockable) => (
              // @ts-ignore
              <Badge key={unlockable} color={UNLOCKABLE_COLORS[unlockable]}>
                {unlockable}
              </Badge>
            ))}
          </Flex>
        );
    }
  }

  return (
    <Flex direction="column" gap="2">
      <SegmentedControl.Root defaultValue={activeGuild} className="topNav">
        {GUILDS.map((guild) => (
          <SegmentedControl.Item
            key={guild}
            value={guild}
            onClick={() => setActiveGuild(guild)}
          >
            {guild}
          </SegmentedControl.Item>
        ))}
      </SegmentedControl.Root>
      {campaign?.levels?.map((level, levelIndex) => {
        const isEditing = level.id === editingCampaignLevel?.id;
        return (
          <Card key={level.id}>
            <Flex gap="3">
              <Grid columns="1fr 1px 1fr" flexGrow="1" gap="4">
                <Flex gap="3" direction="column">
                  <Heading size="3">
                    <Flex gap="1">
                      Level {levelIndex + 1} -{' '}
                      {isEditing ? (
                        <TextField.Root
                          size="1"
                          variant="soft"
                          value={editingCampaignLevel.title}
                          placeholder="Objective name..."
                          onChange={(event) => {
                            updateEditingCampaignLevelTitle(event.target.value);
                          }}
                        />
                      ) : (
                        level.title
                      )}
                    </Flex>
                  </Heading>
                  <Text as="div" size="2">
                    {isEditing ? (
                      <TextArea
                        placeholder="The story of this level is..."
                        variant="soft"
                        value={editingCampaignLevel.story}
                        rows={8}
                        onChange={(event) => {
                          updateEditingCampaignLevelStory(event.target.value);
                        }}
                      />
                    ) : (
                      level.story
                    )}
                  </Text>
                  <Text as="div" size="2">
                    {isEditing ? (
                      <TextArea
                        placeholder="The objective of this level is..."
                        variant="soft"
                        value={editingCampaignLevel.objective}
                        rows={8}
                        onChange={(event) => {
                          updateEditingCampaignLevelObjective(
                            event.target.value
                          );
                        }}
                      />
                    ) : (
                      <>
                        <b>Objective</b>: {level.objective}
                      </>
                    )}
                  </Text>
                </Flex>
                <Separator
                  decorative
                  orientation="vertical"
                  size="4"
                  color="gray"
                />
                <Flex gap="3" direction="column">
                  <SegmentedControl.Root
                    size="1"
                    value={
                      activeCampaignDetailTypeByLevelId[level.id] || 'details'
                    }
                  >
                    <SegmentedControl.Item
                      value="details"
                      onClick={(event) => {
                        if (event.shiftKey) {
                          setActiveCampaignDetailType(
                            campaign.levels,
                            'details'
                          );
                        } else {
                          setActiveCampaignDetailTypeByLevelId(
                            level.id,
                            'details'
                          );
                        }
                      }}
                    >
                      Details
                    </SegmentedControl.Item>
                    <SegmentedControl.Item
                      value="missions"
                      onClick={(event) => {
                        if (event.shiftKey) {
                          setActiveCampaignDetailType(
                            campaign.levels,
                            'missions'
                          );
                        } else {
                          setActiveCampaignDetailTypeByLevelId(
                            level.id,
                            'missions'
                          );
                        }
                      }}
                    >
                      Missions ({level.missions.length})
                    </SegmentedControl.Item>
                    <SegmentedControl.Item
                      value="unlockables"
                      onClick={(event) => {
                        if (event.shiftKey) {
                          setActiveCampaignDetailType(
                            campaign.levels,
                            'unlockables'
                          );
                        } else {
                          setActiveCampaignDetailTypeByLevelId(
                            level.id,
                            'unlockables'
                          );
                        }
                      }}
                    >
                      Unlockables ({level.unlockables.length})
                    </SegmentedControl.Item>
                  </SegmentedControl.Root>
                  <Flex gap="3" direction="column">
                    {renderReward(level)}
                  </Flex>
                </Flex>
              </Grid>
              {!isEditing ? (
                <IconButton
                  size="1"
                  onClick={() => setEditingCampaignLevel(level)}
                >
                  <Pencil2Icon />
                </IconButton>
              ) : (
                <Flex direction="column" gap="1">
                  <IconButton
                    color="green"
                    size="1"
                    onClick={() => {
                      updateCampaignLevel(editingCampaignLevel);
                      setEditingCampaignLevel(undefined);
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    color="red"
                    size="1"
                    onClick={() => setEditingCampaignLevel(undefined)}
                  >
                    <Cross2Icon />
                  </IconButton>
                </Flex>
              )}
            </Flex>
          </Card>
        );
      })}
    </Flex>
  );
}
